export class ModuloModel {

  constructor(
    public moduloId?: number,
    public padreId?: number,
    public orden?: number,
    public principal?: boolean | any,
    public visible?: boolean | any,
    public leftMenu?: boolean | any,
    public topMenu?: boolean | any,
    public megaMenu?: boolean | any,
    public moduloNombre?: string,
    public titulo?: string,
    public icono?: string,
    public etiqueta?: string,
    public etiquetaClase?: string,
    public path?: string,
    public alineacion?: string,
    public routerLink?: string,
    public routerLinkActiveOptions?: string,
    public routerLinkActive?: string,
    public clase?: string,
    public createdBy?: number,
    public createdAt?: Date | any,
    public updatedBy?: number,
    public updatedAt?: Date | any,
    public disabledBy?: number,
    public disabledAt?: Date | any,
    public active?: boolean | any,

  ) { }
}

