import { environment } from 'src/environments/environment';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { UserModel } from 'src/app/models/_codemono/user.model';
import { AuthenticationService } from 'src/app/services/_codemono/authentication.service';
import { IeventsService } from 'src/app/pages/i-events.service';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Output() sidebar = new EventEmitter<boolean>();
  @Output() sidebarMobile = new EventEmitter<boolean>();

  @Output() filterSidebar = new EventEmitter<boolean>();

  public currentUserSubject: BehaviorSubject<UserModel> | any;

  // Registred
  registred = false;

  // User
  currentUser: UserModel | any;

  // Title app
  title = 'DAROCH';

  subTitle = 'Administrador';

  updateText= 'Última Actualización';

  updateDate = this.formatDate(new Date);

  updateTime =  this.formatAMPM(new Date);

  // SideBars
  toggleActiveSidebar = false;
  toggleActiveFilterSidebar = false;


  // Active Navbar
  activeNav = true;
  fluidNav = false;
  production = environment.production;
  
  usuario: any;
  user: any;
  rutaAvatar = environment.URL_SER_NODE;
  usuarioAutenticado: any;
  // tslint:disable-next-line: max-line-length
  constructor(
    public auth: AuthenticationService, 
    public router: Router,
    private httpClient: HttpClient,
    public events: IeventsService) {
    // Navbar navigation effect
    router.events.forEach((event) => {
      if(event instanceof NavigationEnd) {
        this.activeNav =  (event.url==='/start' || event.url==='/product') ? (true) : (false);
        this.fluidNav =  (event.url.includes("/management")) ? (true) : (false);
      }
    });
    this.events.onCargarImagen.subscribe(() => {
      const { Data } = JSON.parse(localStorage.getItem('usuario')||'{}');
      this.usuario = Data;
    });
    this.usuarioAutenticado = this.auth.currentUserValue?.userTypeId;
  }


  
  // If the user is logged in, load the current user
  ngOnInit(): void {
    this.registred = true;
    this.user = this.auth.currentUserValue;
    this.httpClient.get("assets/json/user.json").subscribe({
      next: (res: any) => {
        localStorage.setItem('usuario', JSON.stringify(res.Data) )
      },
      error: (e) => console.error(e),
      complete: () => {
        const Data = JSON.parse(localStorage.getItem('usuario') || '{}');
        this.usuario = Data;
        // console.log(Data);
      }
    });
  }
  // It emits an event to the parent component, which is the `app.component.ts` file
  openSidebar(): void {
    this.toggleActiveSidebar = !this.toggleActiveSidebar;
    this.sidebar.emit(this.toggleActiveSidebar);
  }

  openSidebarMobile(): void {
    this.toggleActiveSidebar = !this.toggleActiveSidebar;
    this.sidebarMobile.emit(this.toggleActiveSidebar);
  }

  openFilterSideBar(): void {
    this.toggleActiveFilterSidebar = true;
    this.filterSidebar.emit(this.toggleActiveFilterSidebar);
  }
  // It calls the logout() function of the auth service
  signOut(): void {
    this.registred = false;
    this.auth.logout();
  }

  formatAMPM(date: any): string {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  formatDate(date: any): string {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }

  openModalFavoritos() {
    this.events.onModalFavoritos.emit(null);
  }
}
