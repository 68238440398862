import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { FiltrosModule } from './components/filtros/filtros.module';
import { FooterModule } from './components/footer/footer.module';
import { NavbarModule } from './components/navbar/navbar.module';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { LayoutBaseComponent } from './layout/layout-base/layout-base.component';
import { LayoutCoreComponent } from './layout/layout-core/layout-core.component';
import { LayoutLandingComponent } from './layout/layout-landing/layout-landing.component';
import { SidebarModule } from 'primeng/sidebar';
import { ListboxModule } from 'primeng/listbox';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import {DialogModule} from 'primeng/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard } from './guards/auth.guard';
import { InterceptorError } from './services/_codemono/interceptor.error.service';
import { InterceptorJwt } from './services/_codemono/interceptor.jwt.service';
import { LayoutInvoiceComponent } from './layout/layout-invoice/layout-invoice.component';
import { ProduccionSurtidoListComponent } from './components/produccion-surtido-list/produccion-surtido-list.component';

@NgModule({
  declarations: [
    AppComponent,
    LayoutBaseComponent,
    LayoutLandingComponent,
    SidebarComponent,
    LayoutCoreComponent,
    LayoutInvoiceComponent,
    ProduccionSurtidoListComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NavbarModule,
    FooterModule,
    SidebarModule,
    FiltrosModule,
    ListboxModule,
    ScrollPanelModule,
    DialogModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorJwt, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorError, multi: true },
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
