  <p-tabView>
    <p-tabPanel header="COMERCIAL">
      <div class="row">
        <div class="col-12 col-md-4 col-lg-4">
          <p-panel header="FAMILIA" [toggleable]="true" expandIcon="mdi mdi-chevron-down mdi-24px" collapseIcon="mdi mdi-chevron-up mdi-24px">
            <div class="row">
              <div class="mt-1 mb-2 col-12  ">
                <div class="form-group">
                  <label for="nombre">FAMILIA</label>
                  <p-multiSelect inputId="multiselect" styleClass="multiWidth" [options]="cities" display="chip" appendTo="body"
                    optionLabel="name" [filter]="true"></p-multiSelect>
                </div>
              </div>
              <div class="mt-1 mb-2 col-12  ">
                <div class="form-group">
                  <label for="nombre">SUBFAMILIA</label>
                  <p-treeSelect containerStyleClass="multiWidth" [options]="multiSelect" display="chip"
                    [metaKeySelection]="false" selectionMode="checkbox" placeholder="" appendTo="body"
                    [filter]="true" [filterInputAutoFocus]="true"></p-treeSelect>
                </div>
              </div>
              <div class="mt-1 mb-2 col-12  ">
                <div class="form-group">
                  <label for="nombre">PLANTA</label>
                  <p-multiSelect inputId="multiselect" styleClass="multiWidth" [options]="cities" display="chip" appendTo="body"
                    optionLabel="name" [filter]="true"></p-multiSelect>
                </div>
              </div>
              <div class="mt-1 mb-2 col-12  ">
                <div class="form-group">
                  <label for="nombre">LINEA</label>
                  <p-multiSelect inputId="multiselect" styleClass="multiWidth" [options]="cities" display="chip" appendTo="body"
                    optionLabel="name" [filter]="true"></p-multiSelect>
                </div>
              </div>
            </div>
          </p-panel>
        </div>
        <div class="col-12 col-md-4 col-lg-4">
          <p-panel header="ESTRUCTURA" [toggleable]="true" expandIcon="mdi mdi-chevron-down mdi-24px" collapseIcon="mdi mdi-chevron-up mdi-24px">
            <div class="row">
              <div class="mt-1 mb-2 col-12  ">
                <div class="form-group">
                  <label for="nombre">ESTRUCTURA</label>
                  <p-multiSelect inputId="multiselect" styleClass="multiWidth" display="chip" [options]="cities" appendTo="body"
                    optionLabel="name" [filter]="true"></p-multiSelect>
                </div>
              </div>
              <div class="mt-1 mb-2 col-12  ">
                <div class="form-group">
                  <label for="nombre">CLIENTE</label>
                  <p-treeSelect containerStyleClass="multiWidth" [options]="multiSelect" display="chip"
                    [metaKeySelection]="false" selectionMode="checkbox" placeholder="" appendTo="body"
                    [filter]="true" [filterInputAutoFocus]="true"></p-treeSelect>
                </div>
              </div>
              <div class="mt-1 mb-2 col-12  ">
                <div class="form-group">
                  <label for="nombre">RESPONSABLE</label>
                  <p-multiSelect inputId="multiselect" styleClass="multiWidth" [options]="cities" display="chip" appendTo="body"
                    optionLabel="name" [filter]="true"></p-multiSelect>
                </div>
              </div>
              <div class="mt-1 mb-2 col-12  ">
                <div class="form-group">
                  <label for="nombre">CLASE</label>
                  <p-multiSelect inputId="multiselect" styleClass="multiWidth" [options]="cities" display="chip" appendTo="body"
                    optionLabel="name" [filter]="true"></p-multiSelect>
                </div>
              </div>
            </div>
          </p-panel>
        </div>
        <div class="col-12 col-md-4 col-lg-4">
          <p-panel header="PRODUCTO" [toggleable]="true" expandIcon="mdi mdi-chevron-down mdi-24px" collapseIcon="mdi mdi-chevron-up mdi-24px">
            <div class="row">
              <div class="mt-1 mb-2 col-12  ">
                <div class="form-group">
                  <label for="nombre">NORMAGRADO</label>
                  <p-multiSelect inputId="multiselect" styleClass="multiWidth" [options]="cities" display="chip" appendTo="body"
                    optionLabel="name" [filter]="true"></p-multiSelect>
                </div>
              </div>
              <div class="mt-1 mb-2 col-12  ">
                <div class="form-group">
                  <label for="nombre">TIPOACERO</label>
                  <p-treeSelect containerStyleClass="multiWidth" [options]="multiSelect" display="chip" appendTo="body"
                    [metaKeySelection]="false" selectionMode="checkbox" placeholder="" [filter]="true"
                    [filterInputAutoFocus]="true"></p-treeSelect>
                </div>
              </div>
              <div class="mt-1 mb-2 col-12  ">
                <div class="form-group">
                  <label for="nombre">PRAM</label>
                  <p-multiSelect inputId="multiselect" styleClass="multiWidth" [options]="cities" display="chip" appendTo="body"
                    optionLabel="name" [filter]="true"></p-multiSelect>
                </div>
              </div>
              <div class="mt-1 mb-2 col-12  ">
                <div class="form-group">
                  <label for="nombre">SGC</label>
                  <p-multiSelect inputId="multiselect" styleClass="multiWidth" [options]="cities" display="chip" appendTo="body"
                    optionLabel="name" [filter]="true"></p-multiSelect>
                </div>
              </div>
            </div>
          </p-panel>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
<div class="row">
  <div class="col-12 d-flex justify-content-end mt-3">
    <button type="button" class="btn btn-default me-3">LIMPIAR</button>
    <button type="button" class="btn btn-light" style="background-color: #31485e; color: #ffffff !important;" type="submit">BUSCAR</button>
  </div>
</div>
