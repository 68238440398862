<!-- Sidebar -->
<p-sidebar [(visible)]="sidebar" [style]="{width:'19rem'}" [showCloseIcon]="true" position="left">
    <app-sidebar></app-sidebar>
</p-sidebar>

<!-- navbar component -->
<app-navbar class="shadow" (sidebar)="sidebar = $event"></app-navbar>

<!-- router content -->
<router-outlet></router-outlet>

<!-- footer component -->
<app-footer></app-footer>