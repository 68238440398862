<!-- Start >> Sidebar  -->
<nav class="sidebar">
  <!-- Start >> Header Sidebar -->
  <div class="sidebar-header text-muted text-center border-bottom" style="padding: 1rem !important;">
    <h5 class="fw-bold">DAROCH</h5>
  </div>
  <!-- End >> Header Sidebar -->

  <ul class="list-unstyled components">
    <!--
      <li class="list-group-item border-0" [routerLink]="['/tablero']">
        <a class="accordion-button collapsed" routerLinkActive="active">
            <i class="mdi mdi-view-dashboard-outline mdi-18px me-3"></i> Tablero
        </a>
      </li>
          <li class="list-group-item border-0" [routerLink]="['/management/todo']">
      <a class="accordion-button collapsed" routerLinkActive="active" href="javascript:void(0)">
        <i class="mdi mdi-checkbox-marked-circle-plus-outline mdi-18px me-3"></i> ToDo
      </a>
    </li>
    -->

    <li class="list-group-item border-0" [routerLink]="['/administracion/usuario']">
      <a class="accordion-button collapsed" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
        href="javascript:void(0)">
        <i class="mdi mdi-account-multiple mdi-18px me-3"></i> Usuarios
      </a>
    </li>

    <li class="list-group-item border-0" [routerLink]="['/producto']">
      <a class="accordion-button collapsed" routerLinkActive="active" href="javascript:void(0)">
        <i class="mdi mdi-shopping mdi-18px me-3"></i> Productos
      </a>
    </li>

    <li class="list-group-item border-0" [routerLink]="['/inventario']">
      <a class="accordion-button collapsed" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
        href="javascript:void(0)">
        <i class="mdi mdi-office-building-marker-outline mdi-18px me-3"></i> Inventario
      </a>
    </li>

    <li class="list-group-item border-0" [routerLink]="['/reportes/ventas']">
      <a class="accordion-button collapsed" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
        href="javascript:void(0)">
        <i class="mdi mdi-percent-box mdi-18px me-3"></i> Ventas
      </a>
    </li>

    <li class="list-group-item border-0" [routerLink]="['/produccion']">
      <a class="accordion-button collapsed" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
        href="javascript:void(0)">
        <i class="mdi mdi-factory mdi-18px me-3"></i> Producción
      </a>
    </li>
    <!--
    <li class="list-group-item border-0" [routerLink]="['/management/general/movimientos']"  *ngIf="enviroment.production === false">
      <a class="accordion-button collapsed" routerLinkActive="active" href="javascript:void(0)">
        <i class="mdi mdi-swap-horizontal mdi-18px me-3"></i> Movimientos
      </a>
    </li>

    <li class="list-group-item border-0"  *ngIf="enviroment.production === false">
      <div class="accordion accordion-flush" id="administracion">
        <div class="accordion-item">
          <div id="flushH-administracion">
            <button class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#flush-administracion"
              aria-expanded="false" aria-controls="flush-administracion"
              [ngClass]="{'btn-active': validateRoute('management/administracion')}">
              <i class="mdi mdi-domain mdi-18px  me-3"></i> Administración
            </button>
          </div>
          <div id="flush-administracion" class="accordion-collapse collapse" aria-labelledby="flushH-administracion"
            data-bs-parent="#administracion">
            <div class="accordion-body">
              <ul class="list-unstyled" id="ngboostrap">
                <li>
                  <a href="javascript:void(0);" [routerLink]="['/management/administracion/sucursal']"
                    routerLinkActive="active">Sucursales</a>
                </li>
                <li>
                  <a href="javascript:void(0);" [routerLink]="['/management/administracion/proveedor']"
                    routerLinkActive="active">Proveedores</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </li>

    <li class="list-group-item border-0"  *ngIf="enviroment.production === false">
      <div class="accordion accordion-flush" id="configuracion">
        <div class="accordion-item">
          <div id="flushH-configuracion">
            <button class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#flush-configuracion"
              aria-expanded="false" aria-controls="flush-configuracion"
              [ngClass]="{'btn-active': validateRoute('management/configuracion')}">
              <i class="mdi mdi-cog mdi-18px  me-3"></i> Configuración
            </button>
          </div>
          <div id="flush-configuracion" class="accordion-collapse collapse" aria-labelledby="flushH-configuracion"
            data-bs-parent="#configuracion">
            <div class="accordion-body">
              <ul class="list-unstyled" id="ngboostrap">
                <li>
                  <a href="javascript:void(0);" [routerLink]="['/management/configuracion/negocio']"
                    routerLinkActive="active">Negocio</a>
                </li>
                <li>
                  <a href="javascript:void(0);" [routerLink]="['/management/configuracion/caracteristica']"
                    routerLinkActive="active">Caracteristicas</a>
                </li>
                <li>
                  <a href="javascript:void(0);" [routerLink]="['/management/configuracion/producto-caracteristica']"
                    routerLinkActive="active">ProductoCaracteristica</a>
                </li>
                <li>
                  <a href="javascript:void(0);" [routerLink]="['/management/configuracion/producto-departamento']"
                    routerLinkActive="active">ProductoDepartamento</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </li>

    <li class="list-group-item border-0"  *ngIf="enviroment.production === false">
      <div class="accordion accordion-flush" id="comercio">
        <div class="accordion-item">
          <div id="flushH-comercio">
            <button class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#flush-comercio"
              aria-expanded="false" aria-controls="flush-comercio"
              [ngClass]="{'btn-active': validateRoute('management/comercio')}">
              <i class="mdi mdi-domain mdi-18px  me-3"></i> Comercio
            </button>
          </div>
          <div id="flush-comercio" class="accordion-collapse collapse" aria-labelledby="flushH-comercio"
            data-bs-parent="#comercio">
            <div class="accordion-body">
              <ul class="list-unstyled" id="ngboostrap">

                <li>
                  <a href="javascript:void(0);" [routerLink]="['/management/comercio/departamento']"
                    routerLinkActive="active">Departamento</a>
                </li>
                <li>
                  <a href="javascript:void(0);" [routerLink]="['/management/comercio/galeria']"
                    routerLinkActive="active">Galeria</a>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </li>

    <li class="list-group-item border-0"  *ngIf="enviroment.production === false">
      <div class="accordion accordion-flush" id="sistema">
        <div class="accordion-item">
          <div id="flushH-sistema">
            <button class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#flush-sistema"
              aria-expanded="false" aria-controls="flush-configuracion"
              [ngClass]="{'btn-active': validateRoute('management/sistema')}">
              <i class="mdi mdi-desktop-classic mdi-18px  me-3"></i> Sistema
            </button>
          </div>
          <div id="flush-sistema" class="accordion-collapse collapse" aria-labelledby="flushH-sistema"
            data-bs-parent="#sistema">
            <div class="accordion-body">
              <ul class="list-unstyled" id="ngboostrap">
                <li>
                  <a href="javascript:void(0);" [routerLink]="['/management/sistema/tipo-galeria']"
                    routerLinkActive="active">TipoGaleria</a>
                </li>
                <li>
                  <a href="javascript:void(0);" [routerLink]="['/management/sistema/tipo-movimiento']"
                    routerLinkActive="active">TipoMovimiento</a>
                </li>
                <li>
                  <a href="javascript:void(0);" [routerLink]="['/management/sistema/tipo-movimiento-operacion']"
                    routerLinkActive="active">TipoMovimientoOperacion</a>
                </li>
                <li>
                  <a href="javascript:void(0);" [routerLink]="['/management/sistema/tipo-presentacion']"
                    routerLinkActive="active">TipoPresentacion</a>
                </li>
                <li>
                  <a href="javascript:void(0);" [routerLink]="['/management/sistema/tipo-venta']"
                    routerLinkActive="active">TipoVenta</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </li>

    <li class="list-group-item border-0"  *ngIf="enviroment.production === false">
      <div class="accordion accordion-flush" id="accordionadministracion">
        <div class="accordion-item">
          <div id="flush-headingOne">
            <button class="accordion-button collapsed" data-bs-toggle="collapse"
              data-bs-target="#flush-collapseadministracion" aria-expanded="false"
              aria-controls="flush-collapseadministracion">
              <i class="bi bi-box-fill me-3"></i> administracion
            </button>
          </div>
          <div id="flush-collapseadministracion" class="accordion-collapse collapse"
            aria-labelledby="flush-headingadministracion" data-bs-parent="#accordionadministracion">
            <div class="accordion-body">
              <ul class="list-unstyled" id="ngboostrap">
                <li>
                  <a href="javascript:void(0);" [routerLink]="['/administracion/modulo']"
                    routerLinkActive="active">Modulo</a>
                </li>
                <li>
                  <a href="javascript:void(0);" [routerLink]="['/administracion/usuario']"
                    routerLinkActive="active">Usuario</a>
                </li>
                <li>
                  <a href="javascript:void(0);" [routerLink]="['/administracion/usuario-contacto']"
                    routerLinkActive="active">UsuarioContacto</a>
                </li>
                <li>
                  <a href="javascript:void(0);" [routerLink]="['/administracion/usuario-contacto-tipo']"
                    routerLinkActive="active">UsuarioContactoTipo</a>
                </li>
                <li>
                  <a href="javascript:void(0);" [routerLink]="['/administracion/usuario-genero']"
                    routerLinkActive="active">UsuarioGenero</a>
                </li>
                <li>
                  <a href="javascript:void(0);" [routerLink]="['/administracion/usuario-rol']"
                    routerLinkActive="active">UsuarioRol</a>
                </li>





              </ul>
            </div>
          </div>
        </div>
      </div>
    </li>

 
    <li class="list-group-item border-0">
      <div class="accordion accordion-flush" id="accordionadministracion">
          <div class="accordion-item">
              <div id="flush-headingOne">
                  <button class="accordion-button collapsed" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseadministracion" aria-expanded="false" aria-controls="flush-collapseadministracion">
                      <i class="bi bi-box-fill me-3"></i> administracion
                  </button>
              </div>
              <div id="flush-collapseadministracion" class="accordion-collapse collapse" aria-labelledby="flush-headingadministracion"
                  data-bs-parent="#accordionadministracion">
                  <div class="accordion-body">
                      <ul class="list-unstyled" id="ngboostrap">
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/administracion/modulo']"
                                  routerLinkActive="active">Modulo</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/administracion/usuario']"
                                  routerLinkActive="active">Usuario</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/administracion/usuario-contacto']"
                                  routerLinkActive="active">UsuarioContacto</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/administracion/usuario-contacto-tipo']"
                                  routerLinkActive="active">UsuarioContactoTipo</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/administracion/usuario-genero']"
                                  routerLinkActive="active">UsuarioGenero</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/administracion/usuario-rol']"
                                  routerLinkActive="active">UsuarioRol</a>
                          </li>





                      </ul>
                  </div>
              </div>
          </div>
      </div>
  </li>
  <li class="list-group-item border-0">
      <div class="accordion accordion-flush" id="accordioncatalogo">
          <div class="accordion-item">
              <div id="flush-headingOne">
                  <button class="accordion-button collapsed" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapsecatalogo" aria-expanded="false" aria-controls="flush-collapsecatalogo">
                      <i class="bi bi-box-fill me-3"></i> catalogo
                  </button>
              </div>
              <div id="flush-collapsecatalogo" class="accordion-collapse collapse" aria-labelledby="flush-headingcatalogo"
                  data-bs-parent="#accordioncatalogo">
                  <div class="accordion-body">
                      <ul class="list-unstyled" id="ngboostrap">
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/catalogo/categoria']"
                                  routerLinkActive="active">Categoria</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/catalogo/coleccion']"
                                  routerLinkActive="active">Coleccion</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/catalogo/color']"
                                  routerLinkActive="active">Color</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/catalogo/departamento']"
                                  routerLinkActive="active">Departamento</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/catalogo/estilo']"
                                  routerLinkActive="active">Estilo</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/catalogo/material']"
                                  routerLinkActive="active">Material</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/catalogo/talla']"
                                  routerLinkActive="active">Talla</a>
                          </li>






                      </ul>
                  </div>
              </div>
          </div>
      </div>
  </li>
  <li class="list-group-item border-0">
      <div class="accordion accordion-flush" id="accordiondbo">
          <div class="accordion-item">
              <div id="flush-headingOne">
                  <button class="accordion-button collapsed" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapsedbo" aria-expanded="false" aria-controls="flush-collapsedbo">
                      <i class="bi bi-box-fill me-3"></i> dbo
                  </button>
              </div>
              <div id="flush-collapsedbo" class="accordion-collapse collapse" aria-labelledby="flush-headingdbo"
                  data-bs-parent="#accordiondbo">
                  <div class="accordion-body">
                      <ul class="list-unstyled" id="ngboostrap">
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/dbo/base']"
                                  routerLinkActive="active">BASE</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/dbo/centro-distribucion']"
                                  routerLinkActive="active">CentroDistribucion</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/dbo/galeria']"
                                  routerLinkActive="active">Galeria</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/dbo/movimientos']"
                                  routerLinkActive="active">Movimientos</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/dbo/negocio']"
                                  routerLinkActive="active">Negocio</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/dbo/proveedor']"
                                  routerLinkActive="active">Proveedor</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/dbo/sucursal']"
                                  routerLinkActive="active">Sucursal</a>
                          </li>






                      </ul>
                  </div>
              </div>
          </div>
      </div>
  </li>
  <li class="list-group-item border-0">
      <div class="accordion accordion-flush" id="accordionproducto">
          <div class="accordion-item">
              <div id="flush-headingOne">
                  <button class="accordion-button collapsed" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseproducto" aria-expanded="false" aria-controls="flush-collapseproducto">
                      <i class="bi bi-box-fill me-3"></i> producto
                  </button>
              </div>
              <div id="flush-collapseproducto" class="accordion-collapse collapse" aria-labelledby="flush-headingproducto"
                  data-bs-parent="#accordionproducto">
                  <div class="accordion-body">
                      <ul class="list-unstyled" id="ngboostrap">
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/producto/producto']"
                                  routerLinkActive="active">Producto</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/producto/producto-relacion-catalogos']"
                                  routerLinkActive="active">ProductoRelacionCatalogos</a>
                          </li>

                      </ul>
                  </div>
              </div>
          </div>
      </div>
  </li>
  <li class="list-group-item border-0">
      <div class="accordion accordion-flush" id="accordiontipo">
          <div class="accordion-item">
              <div id="flush-headingOne">
                  <button class="accordion-button collapsed" data-bs-toggle="collapse"
                      data-bs-target="#flush-collapsetipo" aria-expanded="false" aria-controls="flush-collapsetipo">
                      <i class="bi bi-box-fill me-3"></i> tipo
                  </button>
              </div>
              <div id="flush-collapsetipo" class="accordion-collapse collapse" aria-labelledby="flush-headingtipo"
                  data-bs-parent="#accordiontipo">
                  <div class="accordion-body">
                      <ul class="list-unstyled" id="ngboostrap">
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/tipo/tipo-galeria']"
                                  routerLinkActive="active">TipoGaleria</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/tipo/tipo-movimiento']"
                                  routerLinkActive="active">TipoMovimiento</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/tipo/tipo-movimiento-operacion']"
                                  routerLinkActive="active">TipoMovimientoOperacion</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/tipo/tipo-movimiento-presentacion']"
                                  routerLinkActive="active">TipoMovimientoPresentacion</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/tipo/tipo-presentacion']"
                                  routerLinkActive="active">TipoPresentacion</a>
                          </li>
                          <li>
                              <a href="javascript:void(0);" [routerLink]="['/management/tipo/tipo-venta']"
                                  routerLinkActive="active">TipoVenta</a>
                          </li>





                      </ul>
                  </div>
              </div>
          </div>
      </div>
  </li>
-->
  </ul>

</nav>







<!-- End >> Sidebar  
        <li class="list-group-item border-0">
            <a class="accordion-button collapsed" routerLinkActive="active">
                <i class="bi bi-box-fill me-3"></i> Page Section 1
            </a>
        </li>

        <li class="list-group-item border-0">
            <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item">
                    <div id="flush-headingOne">
                        <button class="accordion-button collapsed" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            <i class="bi bi-box-fill me-3"></i> Page Section 2
                        </button>
                    </div>
                    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                            <ul class="list-unstyled" id="ngboostrap">
                                <li>
                                    <a href="javascript:void(0);"
                                        routerLinkActive="active">Page Sub Section 1</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);"
                                        routerLinkActive="active">Page Sub Section 2</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);"
                                        routerLinkActive="active">Page Sub Section 3</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);"
                                        routerLinkActive="active">Page Sub Section 4</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);"
                                        routerLinkActive="active">Page Sub Section 5</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </li>

        <li class="list-group-item border-0">
            <a class="accordion-button collapsed" routerLinkActive="active">
                <i class="bi bi-box-fill me-3"></i>Page Section 3
            </a>
        </li>
-->