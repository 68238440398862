<!-- Start >> Navbar -->
<nav id="navbar" class="navbar navbar-sm mb-0 px-2">
  <div class="container-fluid" style="padding-left: 0px;">
    <!-- Start >> Navbar items -->
    <ul class="navbar-nav">

      <!-- Nav item sidebar icon -->
      <li class="nav-item btn-menu icon-center" style="justify-content: end;" (click)="openSidebar()" *ngIf="usuarioAutenticado === 1">
        <div class="nav-menu">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </li>

      <li class="nav-item icon-center click" style="padding-left: 3px;" [routerLink]="['/start']">
        <!-- Logo App -->

        <!-- IMG -->
        <img class="img-sm mr-2" width="50" height="50" ngSrc="./assets/images/logos/DDF-1024x1024.png" alt="Ternium" />

        <!-- Title app -->
        <div class="flex-container">
          <div class="flex-items">
            <section class='FlexContainer'>
              <div>
                <h5 class="p-0 m-0 ms-2 fw-bold d-none d-md-block">{{title}}
                </h5>
              </div>
              <div>
                <div class="d-none d-md-block mx-2"
                  style="height: 1rem; width: 1px; background-color: #6d6d6d; position: relative; top: 0.2rem;"></div>
              </div>
              <div>
                <h5 class="p-0 m-0 text-muted d-none d-md-block">{{subTitle}}</h5>
              </div>
            </section>
            <h6 class="p-0 m-0 ms-2 fw-bold d-block d-md-none">{{title}} <br> <span
                class="text-muted">{{subTitle}}</span></h6>

          </div>
          <!--
          <div class="flex-items mt-2 d-none d-md-block">
            <span class="p-0 mb-0 ms-2 text-dark">{{updateText}} <span class="text-muted ms-2">{{updateDate}}</span>
              <span class="text-muted ms-2">|</span> <span class="text-muted ms-2">{{updateTime}}</span> </span>
          </div>
          -->
        </div>

      </li>
    </ul>
    <!--VISTA ESCRITORIO-->
    <div class="ms-auto d-none d-md-block">
      <ul class="navbar-nav">
        <ul class="navbar-nav">
          <li class="nav-item dropdown ">
            <a href="javascript:void(0)" id="navbarDropdownMenuLink1" role="button" (click)="op.toggle($event)">
              <i class="mdi mdi-bell-outline mdi-24px"></i>
            </a>
          </li>
          <!-- Registred User -->
          <li class="nav-item dropdown mx-4">
            <a href="#" id="navbarDropdownMenuLink2" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <img  ngSrc="{{rutaAvatar}}avatar/{{user.avatar}}"  
              onerror="this.onerror=null;this.src='assets/images/avatars/avatar-01.svg';" width="50" height="50" class="user user-chip-md bg-default" alt="user-image">
            </a>
            <div class="dropdown-menu dropdown-animate" aria-labelledby="navbarDropdownMenuLink2">
              <div class="row p-2 d-flex align-items-center" style="width: 20rem;">
                <div class="col-md-3 col-sm-3 col-3">
                  <img  ngSrc="{{rutaAvatar}}avatar/{{user.avatar}}"  
                  onerror="this.onerror=null;this.src='assets/images/avatars/avatar-01.svg';"
                   width="50" height="50" class="user user-chip-xxlg bg-default"
                    alt="user-image">
                </div>
                <div class="col-md-9 col-sm-9 col-9">
                  <div class="py-3 ps-3">
                    <div>
                      <h6 class="text-dark p-0 m-0 text-capitalize fw-bold">
                        {{user?.firstName}}  {{user?.lastName}}
                      </h6>
                    </div>
                    <div>
                      <small class="text-dark text-capitalize">{{user?.userTypeName}}</small>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <hr>
              </div>
              <ul class="list-group list-group-flush">
     
                <li class="list-group-item-user d-flex align-items-center">
                  <i class="mdi mdi-email mdi-24px text-primary fs-5 me-4"></i> {{user?.username}}
                </li>
                <li class="list-group-item-user d-flex align-items-center">
                  <i class="mdi mdi-cellphone-sound mdi-24px text-primary fs-5 me-4"></i> {{usuario?.TelephoneNumber}}
                </li>

                <li class="list-group-item-user d-flex align-items-center" [routerLink]="['/management']"
                  routerLinkActive="item-active" *ngIf="usuarioAutenticado === 1">
                  <i class="bi bi bi-gear-fill text-secondary fs-5 me-4"></i> Administracion General
                </li>
                <li class="list-group-item-user d-flex align-items-center" (click)="signOut()">
                  <i class="bi bi-box-arrow-right text-danger fs-5 me-4"></i> Sign out
                </li>

              </ul>
            </div>
          </li>

          <!-- Not registred -->
          <!-- <button type="button" class="btn btn-login btn-primary" routerLinkActive="router-link-active">Ir a Antiguos</button> -->

        </ul>
      </ul>
    </div>

    <!--VISTA MOBILIDAD-->
    <div class="ms-auto d-block d-sm-none">
      <ul class="navbar-nav">
        <ul class="navbar-nav">
          <li class="nav-item dropdown  mx-3" *ngIf="false">
            <a href="#" id="navbarDropdownMenuLink1" role="button" (click)="openFilterSideBar()">
              <i class="mdi mdi-filter mdi-24px"></i>
            </a>
          </li>
          <!-- Registred User -->
          <li class="nav-item dropdown mx-1">
            <a href="#" id="navbarDropdownMenuLink2" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <img  ngSrc="{{rutaAvatar}}avatar/{{user.avatar}}"  
              onerror="this.onerror=null;this.src='assets/images/avatars/avatar-01.svg';"  width="50" height="50" class="user user-chip-md bg-default" alt="user-image">
            </a>
            <div class="dropdown-menu dropdown-animate" aria-labelledby="navbarDropdownMenuLink2">
              <div class="row p-2 d-flex align-items-center" style="width: 20rem;">
                <div class="col-md-3 col-sm-3 col-3">
                  <img  ngSrc="{{rutaAvatar}}avatar/{{user.avatar}}"  
                  onerror="this.onerror=null;this.src='assets/images/avatars/avatar-01.svg';"  width="50" height="50" class="user user-chip-xxlg bg-default"
                    alt="user-image">
                </div>
                <div class="col-md-9 col-sm-9 col-9">
                  <div class="py-3 ps-3">
                    <div>
                      <h5 class="text-dark p-0 m-0 text-capitalize fw-bold">
                        {{currentUser?.firstName}} {{currentUser?.lastName}}
                      </h5>
                    </div>
                    <div>
                      <small class="text-dark text-capitalize"></small>
                    </div>
                  </div>
                </div>
              </div>
              <ul class="list-group list-group-flush">
                <li class="list-group-item-user d-flex align-items-center" (click)="openModalFavoritos()">
                  <i class="mdi mdi-shield-star-outline mdi-24px text-primary fs-5 me-4"></i> Favoritos
                </li>

                <li class="list-group-item-user d-flex align-items-center" [routerLink]="['/management/user']"
                  routerLinkActive="item-active" *ngIf="usuarioAutenticado === 1">
                  <i class="bi bi bi-gear-fill text-secondary fs-5 me-4"></i> Administracion General
                </li>
                <li class="list-group-item-user d-flex align-items-center" (click)="signOut()">
                  <i class="bi bi-box-arrow-right text-danger fs-5 me-4"></i> Sign out
                </li>

              </ul>
            </div>
          </li>

          <!-- Not registred -->
          <!-- <button type="button" class="btn btn-login btn-primary" routerLinkActive="router-link-active">Ir a Antiguos</button> -->

        </ul>
      </ul>
    </div>

    <!-- End >> Navbar items -->
  </div>
</nav>
<!-- End >> Navbar -->

<p-overlayPanel #op>
  <ng-template pTemplate="content">
    <div class="card" style="width: 360px; border-radius: 50px;">
      <div class="card-header" style="border-radius: 0px; padding: 15px;">
        <span style="margin-bottom: 0px; font-weight: bold;">Notificaciones</span>
      </div>
      <div class="card-body" style="padding: 0px; border-radius: 0px;">
        <div class="list-group">
          <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
            <div class="row align-middle">
              <div class="col-2" style="padding-right: 0px; padding-left: 8px;">
                <img ngSrc="assets/images/avatars/avatar-01.svg" width="50" height="50" class="img-fluid" style="border-radius: 50%;" alt="user avatar">
              </div>
              <div  class="col-10">
                <div class="d-flex w-100 justify-content-between">
                  <span class="mb-1" style="font-size: 15px; font-weight: bold;" >List group item heading</span>
                  <small>3 days ago</small>
                </div>
                <p class="mb-1" style="font-size: 12px;">Donec id elit non mi porta gravida at .</p>
              </div>
            </div>

          </a>
          <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
            <div class="row align-middle">
              <div class="col-2" style="padding-right: 0px; padding-left: 8px;">
                <img ngSrc="assets/images/avatars/avatar-01.svg" class="img-fluid" width="50" height="50" style="border-radius: 50%;" alt="user avatar">
              </div>
              <div  class="col-10">
                <div class="d-flex w-100 justify-content-between">
                  <span class="mb-1" style="font-size: 15px; font-weight: bold;" >List group item heading</span>
                  <small>3 days ago</small>
                </div>
                <p class="mb-1" style="font-size: 12px;">Donec id elit non mi porta gravida at .</p>
              </div>
            </div>

          </a>
          <a href="#" class="list-group-item list-group-item-action flex-column align-items-start">
            <div class="row align-middle">
              <div class="col-2" style="padding-right: 0px; padding-left: 8px;">
                <img ngSrc="assets/images/avatars/avatar-01.svg" class="img-fluid"  width="50" height="50" style="border-radius: 50%;" alt="user avatar">
              </div>
              <div  class="col-10">
                <div class="d-flex w-100 justify-content-between">
                  <span class="mb-1" style="font-size: 15px; font-weight: bold;" >List group item heading</span>
                  <small>3 days ago</small>
                </div>
                <p class="mb-1" style="font-size: 12px;">Donec id elit non mi porta gravida at .</p>
              </div>
            </div>

          </a>          
        </div>

      </div>
      <div class="card-footer"  style="padding: 10px; ">
        <button class="btn btn-sm btn-info" style="width: 100%;" type="button">Ver Todo</button>
      </div>
    </div>

  </ng-template>
</p-overlayPanel>


