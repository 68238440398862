import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutCoreComponent } from './layout/layout-core/layout-core.component';
import { LayoutInvoiceComponent } from './layout/layout-invoice/layout-invoice.component';

const routes: Routes = [
  {
    path: 'authentication',
    loadChildren: () =>
      import('./pages/authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: 'home',
    component: LayoutCoreComponent,
    //canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/home/home.module').then((m) => m.HomeModule),
      },
    ],
  },
  {
    path: 'management',
    component: LayoutCoreComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/management/management.module').then((m) => m.ManagementModule),
      }
    ]
  },
  {
    path: 'inventario',
    component: LayoutCoreComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/inventario/inventario.module').then((m) => m.InventarioModule),
      }
    ]
  },
  {
    path: 'producto',
    component: LayoutCoreComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/producto/producto.module').then((m) => m.ProductoModule),
      }
    ]
  },
  {
    path: 'ventas',
    component: LayoutCoreComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/ventas/ventas.module').then((m) => m.VentasModule),
      }
    ]
  }, 
  {
    path: 'reportes',
    component: LayoutCoreComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/reportes/reportes.module').then((m) => m.ReportesModule),
      }
    ]
  },  
  {
    path: 'invoice',
    component: LayoutInvoiceComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/invoice/invoice.module').then((m) => m.InvoiceModule),
      }
    ]
  }, 
  {
    path: 'produccion',
    component: LayoutCoreComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/produccion/produccion.module').then((m) => m.ProduccionModule),
      }
    ]
  },
  {
    path: 'retailer',
    component: LayoutCoreComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/cliente/usuario.module').then((m) => m.UsuarioModule),
      }
    ]
  }, 
  {
    path: 'pedidos',
    component: LayoutCoreComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/pedidos/orden/orden.module').then((m) => m.OrdenModule),
      }
    ]
  }, 
  {
    path: 'cupones',
    component: LayoutCoreComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/cupones/cuponera.module').then((m) => m.CuponeraModule),
      }
    ]
  },   
  {
    path: 'corte',
    component: LayoutCoreComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/corte/orden-corte.module').then((m) => m.OrdenCorteModule),
      }
    ]
  }, 
  {
    path: 'notificaciones',
    component: LayoutCoreComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/notificacion/notificacion.module').then((m) => m.NotificacionModule),
      }
    ]
  }, 
  {
    path: 'tarjetas',
    component: LayoutCoreComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/gift-card/gift-card.module').then((m) => m.GiftCardModule),
      }
    ]
  },  
  {
    path: 'cajas',
    component: LayoutCoreComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/caja-inventario/caja-inventario.module').then((m) => m.CajaInventarioModule),
      }
    ]
  },  
  {
    path: 'widget/uno',
    component: LayoutCoreComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/widget-uno/widget-uno.module').then((m) => m.WidgetUnoModule),
      }
    ]
  },  
  {
    path: 'devoluciones',
    component: LayoutCoreComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/devoluciones/orden/orden.module').then((m) => m.OrdenModule),
      }
    ]
  },  
  {
    path: 'fest',
    component: LayoutCoreComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/fest/fest.module').then((m) => m.FestModule),
      }
    ]
  }, 
  {
    path: 'administracion/modulo',
    component: LayoutCoreComponent,
    loadChildren: () => import('./pages/administracion/modulo/modulo.module').then((m) => m.ModuloModule),
  },
  {
    path: 'administracion/usuario',
    component: LayoutCoreComponent,
    loadChildren: () => import('./pages/admin/usuario/usuario.module').then((m) => m.UsuarioModule),
  },
  {
    path: 'administracion/usuario-contacto',
    component: LayoutCoreComponent,
    loadChildren: () => import('./pages/administracion/usuario-contacto/usuario-contacto.module').then((m) => m.UsuarioContactoModule),
  },
  {
    path: 'administracion/usuario-contacto-tipo',
    component: LayoutCoreComponent,
    loadChildren: () => import('./pages/administracion/usuario-contacto-tipo/usuario-contacto-tipo.module').then((m) => m.UsuarioContactoTipoModule),
  },
  {
    path: 'administracion/usuario-genero',
    component: LayoutCoreComponent,
    loadChildren: () => import('./pages/administracion/usuario-genero/usuario-genero.module').then((m) => m.UsuarioGeneroModule),
  },
  {
    path: 'administracion/usuario-rol',
    component: LayoutCoreComponent,
    loadChildren: () => import('./pages/administracion/usuario-rol/usuario-rol.module').then((m) => m.UsuarioRolModule),
  },  

  {
    path: 'authentication/login',
    redirectTo: 'authentication/login',
    pathMatch: 'full'
  },
  
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
