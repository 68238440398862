import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ModuloModel } from 'src/app/models/administracion/modulo.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModuloService {

  constructor(private http: HttpClient) { }

  get(id: number){
    const url = environment.URL_SER_NODE + `administracion/modulo/${id}`;
    const res = this.http.get(url);
    return res;
  }

  getAll(modulo: ModuloModel): Observable<ModuloModel>{
    let params = new HttpParams();
    if (modulo.moduloId != null) { params = params.append('moduloId', modulo.moduloId.toString()); }
    if (modulo.principal != null) { params = params.append('principal', modulo.principal.toString()); }
    if (modulo.visible != null) { params = params.append('visible', modulo.visible.toString()); }
    if (modulo.leftMenu != null) { params = params.append('leftMenu', modulo.leftMenu.toString()); }
    if (modulo.topMenu != null) { params = params.append('topMenu', modulo.topMenu.toString()); }
    if (modulo.megaMenu != null) { params = params.append('megaMenu', modulo.megaMenu.toString()); }
    if (modulo.moduloNombre != null) { params = params.append('moduloNombre', modulo.moduloNombre.toString()); }
    if (modulo.titulo != null) { params = params.append('titulo', modulo.titulo.toString()); }
    if (modulo.icono != null) { params = params.append('icono', modulo.icono.toString()); }
    if (modulo.etiqueta != null) { params = params.append('etiqueta', modulo.etiqueta.toString()); }
    if (modulo.etiquetaClase != null) { params = params.append('etiquetaClase', modulo.etiquetaClase.toString()); }
    if (modulo.path != null) { params = params.append('path', modulo.path.toString()); }
    if (modulo.alineacion != null) { params = params.append('alineacion', modulo.alineacion.toString()); }
    if (modulo.routerLink != null) { params = params.append('routerLink', modulo.routerLink.toString()); }
    if (modulo.routerLinkActiveOptions != null) { params = params.append('routerLinkActiveOptions', modulo.routerLinkActiveOptions.toString()); }
    if (modulo.routerLinkActive != null) { params = params.append('routerLinkActive', modulo.routerLinkActive.toString()); }
    if (modulo.clase != null) { params = params.append('clase', modulo.clase.toString()); }
    if (modulo.active != null) { params = params.append('active', modulo.active.toString()); }

    const url = environment.URL_SER_NODE + `administracion/modulo`;
    const res = this.http.get(url, { params });
    return res;
  }

  getList(modulo: ModuloModel): Observable<ModuloModel>{
    let params = new HttpParams();
    if (modulo.moduloId != null) { params = params.append('moduloId', modulo.moduloId.toString()); }
    if (modulo.principal != null) { params = params.append('principal', modulo.principal.toString()); }
    if (modulo.visible != null) { params = params.append('visible', modulo.visible.toString()); }
    if (modulo.leftMenu != null) { params = params.append('leftMenu', modulo.leftMenu.toString()); }
    if (modulo.topMenu != null) { params = params.append('topMenu', modulo.topMenu.toString()); }
    if (modulo.megaMenu != null) { params = params.append('megaMenu', modulo.megaMenu.toString()); }
    if (modulo.moduloNombre != null) { params = params.append('moduloNombre', modulo.moduloNombre.toString()); }
    if (modulo.titulo != null) { params = params.append('titulo', modulo.titulo.toString()); }
    if (modulo.icono != null) { params = params.append('icono', modulo.icono.toString()); }
    if (modulo.etiqueta != null) { params = params.append('etiqueta', modulo.etiqueta.toString()); }
    if (modulo.etiquetaClase != null) { params = params.append('etiquetaClase', modulo.etiquetaClase.toString()); }
    if (modulo.path != null) { params = params.append('path', modulo.path.toString()); }
    if (modulo.alineacion != null) { params = params.append('alineacion', modulo.alineacion.toString()); }
    if (modulo.routerLink != null) { params = params.append('routerLink', modulo.routerLink.toString()); }
    if (modulo.routerLinkActiveOptions != null) { params = params.append('routerLinkActiveOptions', modulo.routerLinkActiveOptions.toString()); }
    if (modulo.routerLinkActive != null) { params = params.append('routerLinkActive', modulo.routerLinkActive.toString()); }
    if (modulo.clase != null) { params = params.append('clase', modulo.clase.toString()); }
    if (modulo.active != null) { params = params.append('active', modulo.active.toString()); }

    const url = environment.URL_SER_NODE + `administracion/modulo/list`;
    const res = this.http.get(url, { params });
    return res;
  }

  create(modulo: ModuloModel) {
    const url = environment.URL_SER_NODE + `administracion/modulo`;
    const res = this.http.post(url, {
      padreId: modulo.padreId,
      orden: modulo.orden,
      principal: modulo.principal,
      visible: modulo.visible,
      leftMenu: modulo.leftMenu,
      topMenu: modulo.topMenu,
      megaMenu: modulo.megaMenu,
      moduloNombre: modulo.moduloNombre,
      titulo: modulo.titulo,
      icono: modulo.icono,
      etiqueta: modulo.etiqueta,
      etiquetaClase: modulo.etiquetaClase,
      path: modulo.path,
      alineacion: modulo.alineacion,
      routerLink: modulo.routerLink,
      routerLinkActiveOptions: modulo.routerLinkActiveOptions,
      routerLinkActive: modulo.routerLinkActive,
      clase: modulo.clase
     });
    return res;
  }

  update(modulo: ModuloModel) {
    const url = environment.URL_SER_NODE + `administracion/modulo`;
    const res = this.http.put(url, {
      moduloId: modulo.moduloId,
      padreId: modulo.padreId,
      orden: modulo.orden,
      principal: modulo.principal,
      visible: modulo.visible,
      leftMenu: modulo.leftMenu,
      topMenu: modulo.topMenu,
      megaMenu: modulo.megaMenu,
      moduloNombre: modulo.moduloNombre,
      titulo: modulo.titulo,
      icono: modulo.icono,
      etiqueta: modulo.etiqueta,
      etiquetaClase: modulo.etiquetaClase,
      path: modulo.path,
      alineacion: modulo.alineacion,
      routerLink: modulo.routerLink,
      routerLinkActiveOptions: modulo.routerLinkActiveOptions,
      routerLinkActive: modulo.routerLinkActive,
      clase: modulo.clase
    });
    return res;
  }

  enable(moduloId: number) {
    const url = environment.URL_SER_NODE + `administracion/modulo/enable`;
    const res = this.http.put(url, {
      moduloId: moduloId
    });
    return res;
  }

  disable(moduloId: number) {
    const url = environment.URL_SER_NODE + `administracion/modulo/disable`;
    const res = this.http.put(url, {
      moduloId: moduloId
    });
    return res;
  }

  delete(id: string) {
    const url = environment.URL_SER_NODE + `administracion/modulo/${id}`;
    const res = this.http.delete(url);
    return res;
  }

}

