import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';

@Component({
  selector: 'app-filtros',
  templateUrl: './filtros.component.html',
  styleUrls: ['./filtros.component.scss']
})
export class FiltrosComponent {

  cities: any[];
  multiSelect: any[] = [];

  value9: any;
  
  constructor(private httpClient: HttpClient) {
    this.cities = [
      {name: 'Dato Prueba 1', code: 'NY'},
      {name: 'Dato Prueba 2', code: 'RM'},
      {name: 'Dato Prueba 3', code: 'LDN'},
      {name: 'Dato Prueba 4', code: 'IST'},
      {name: 'Dato Prueba 5', code: 'PRS'}
    ];
    this.httpClient.get('assets/json/multiSelect.json').subscribe((res: any) => {
      this.multiSelect = res.data;
    });
  }


}
