import { Component } from '@angular/core';

@Component({
  selector: 'app-produccion-surtido-list',
  templateUrl: './produccion-surtido-list.component.html',
  styleUrls: ['./produccion-surtido-list.component.scss']
})
export class ProduccionSurtidoListComponent {

}
