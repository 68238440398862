import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModuloModel } from 'src/app/models/administracion/modulo.model';
import { IeventsService } from 'src/app/pages/i-events.service';
import { ModuloService } from 'src/app/services/administracion/modulo.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  // Title app
  title = 'Central';
  subTitle = 'Prototipo';
  dashboard: Array<any> | any;

  modulos: any[] = [];
  moduloFilter = new ModuloModel();
  enviroment: any;

  constructor(
    private httpClient: HttpClient,
    private e: IeventsService,
    private router: Router,
    private moduloService: ModuloService
  ) {
    this.enviroment = environment;
  }

  ngOnInit(): void {
    this.load();
  }

  addIndicador(indicador: any) {
    this.e.onAddIndicador.emit(indicador);
  }
  removeIndicador(indicador: any) {
    this.e.onRemoveIndicador.emit(indicador);
  }

  validateRoute(route: string) {
    if (this.router.url.indexOf(route) < 0) {
      return false;
    }
    return true;
  }

  // Load data
  load(): void {

    this.modulos = [];

    this.moduloService.getAll(this.moduloFilter).subscribe(
      (res: any) => {
        this.modulos = res.data;
      },
      (err: any) => {
        //console.log(err);
        Swal.fire('Error', 'Unexpected error', 'error');
      },
      () => {
      }
    );
  }
}
/*
    this.e.onIndicadoresAgregados.subscribe((indicadores: any []) => {
      // console.log(indicadores);
      this.dashboard = this.dashboard.map((d: any) => { 
        let indicador = indicadores.find(x => {
          return (x.id === d.id);
        });
        let itemDash = {
          cols: d.cols,
          id: d.id,
          rows: d.rows,
          title: d.title,
          x: d.x,
          y: d.y,
          exsiste: indicador ? true : false
        };
        return itemDash;
      });
    });

    this.httpClient.get("assets/json/dashboard.json").subscribe((data: any) => {
      this.dashboard = data;
    },
    () => {},
    () => {});*/
