import * as CryptoJS from 'crypto-js';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const SECRET_KEY = environment.APP_ID;

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() { }

  /* sesion storage methods. */
  sesionstorage = {
    saveData(key: string, value: any) {
      let encryptData = CryptoJS.AES.encrypt(JSON.stringify(value), SECRET_KEY).toString();
      sessionStorage.setItem(key, encryptData);
    },
    getData(key: string) {
      try {

        let data = sessionStorage.getItem(key) || "";

        // Decrypt
        var bytes = CryptoJS.AES.decrypt(data, SECRET_KEY);
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        return decryptedData;

      } catch (error) {

      }

    },
    removeData(key: string) {
      sessionStorage.removeItem(key);
    },
    clearData() {
      sessionStorage.clear();
    },
    encrypt(txt: string): string {
      return CryptoJS.AES.encrypt(txt, SECRET_KEY).toString();
    },
    decrypt(txtToDecrypt: string) {
      return CryptoJS.AES.decrypt(txtToDecrypt, SECRET_KEY).toString(CryptoJS.enc.Utf8);
    }
  }

  /* local storage methods. */
  localstorage = {
    saveData(key: string, value: any) {
      let encryptData = CryptoJS.AES.encrypt(JSON.stringify(value), SECRET_KEY).toString();
      localStorage.setItem(key, encryptData);
    },
    getData(key: string) {
      try {

        let data = localStorage.getItem(key) || "";

        // Decrypt
        var bytes = CryptoJS.AES.decrypt(data, SECRET_KEY);
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        return decryptedData;

      } catch (error) {

      }

    },
    removeData(key: string) {
      localStorage.removeItem(key);
    },
    clearData() {
      localStorage.clear();
    },
    encrypt(txt: string): string {
      return CryptoJS.AES.encrypt(txt, SECRET_KEY).toString();
    },
    decrypt(txtToDecrypt: string) {
      return CryptoJS.AES.decrypt(txtToDecrypt, SECRET_KEY).toString(CryptoJS.enc.Utf8);
    }
  }


}
