import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  constructor(private storageService: StorageService) { }

  /* A service that is used to store data in the session storage. */


  /**
   * This function takes a key and a value, and saves the value in the session storage with the key.
   * @param {string} key - string - The key to store the data under.
   * @param {any} value - any -&gt; this is the value that you want to store in the session storage.
   */
  setJsonValue(key: string, value: any) {
    this.storageService.sesionstorage.saveData(key, value);
  }

  /**
   * It gets the value of a key from the session storage
   * @param {string} key - The key of the item you want to get from the session storage.
   * @returns The value of the key.
   */
  getJsonValue(key: string) {
    return this.storageService.sesionstorage.getData(key);
  }

  /**
   * It clears the token from the session storage.
   * @returns The return value is the result of the clearData() method.
   */
  clearToken() {
    return this.storageService.sesionstorage.clearData();
  }
}
