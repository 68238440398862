export class UserModel {

    constructor(
      public option?: number,
      public userId?: number,
      public firstName?: string,
      public lastName?: string,
      public userTypeId?: number,
      public username?: string,
      public password?: string,
      public avatar?: string,
      public phone?: string,
      public phoneType?: string,
      public gender?: string,
      public bio?: string,
      public createdBy?: number,
      public createdAt?: Date,
      public updatedBy?: number,
      public updatedAt?: Date,
      public disabledBy?: number,
      public disabledAt?: Date,
      public active?: any,
      public token?: string
  
    ) { }
  }