import { EventEmitter, Injectable, Output } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class LocalService {

    @Output() cargaDatos: EventEmitter<any> = new EventEmitter();

    constructor(private storageService: StorageService) { }

    /* A service that is used to store data in the local storage. */

    /**
     * Set key and value in local storage
     * @param {string} key - The key to store the data under.
     * @param {any} value - any - This is the value you want to store in the local storage.
     */
    setJsonValue(key: string, value: any): any {
        this.storageService.localstorage.saveData(key, value);
    }

    /**
     * It gets the value of a key from the local storage
     * @param {string} key - The key of the item you want to get from local storage.
     * @returns The value of the key.
     */
    getJsonValue(key: string): any {
        return this.storageService.localstorage.getData(key);
    }

    /**
     * It clears the token from the local storage.
     * @returns The return value is the result of the clearData() function.
     */
    clearToken(): any {
        return this.storageService.localstorage.clearData();
    }

    /**
     * It removes the item from local storage with the key that is passed in.
     * @param {string} key - The key of the item you want to remove from local storage.
     */
    removeItem(key: string) {
        localStorage.removeItem(key);
    }

    /**
     * The function takes a value, emits it, and then returns nothing.
     * @param {any} value - any
     */
    iCarga(value: any) {
        this.cargaDatos.emit(value);
    }
}
