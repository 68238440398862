import { Dialog } from 'primeng/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout-core',
  templateUrl: './layout-core.component.html',
  styleUrls: ['./layout-core.component.scss']
})
export class LayoutCoreComponent implements OnInit {

  sidebar =  false;
  sidebarMobile =  false;
  filterSidebar =  false;

  constructor() { }

  ngOnInit(): void {
  }

  mostrarFiltros(filtros: Dialog) {
    filtros.maximized = true;
    this.filterSidebar = true;
  }
  
}
