import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IeventsService {
  @Output() onCargarImagen: EventEmitter<any> = new EventEmitter();
  @Output() onRecargar: EventEmitter<any> = new EventEmitter();
  @Output() onHabilitarResizeDrag: EventEmitter<any> = new EventEmitter();
  @Output() onDesHabilitarResizeDrag: EventEmitter<any> = new EventEmitter();
  @Output() onAddIndicador: EventEmitter<any> = new EventEmitter();
  @Output() onRemoveIndicador: EventEmitter<any> = new EventEmitter();
  @Output() onIndicadoresAgregados: EventEmitter<any> = new EventEmitter();
  @Output() onModalFavoritos: EventEmitter<any> = new EventEmitter();
  @Output() onCrearFavoritos: EventEmitter<any> = new EventEmitter();
  @Output() onCargarFavoritos: EventEmitter<any> = new EventEmitter();
  @Output() onLoadFavoritos: EventEmitter<any> = new EventEmitter();

  public activo = true;

  constructor() { }

  habilitarReziseDrag() {
    this.activo = true;
    this.onHabilitarResizeDrag.emit(null);
  }
  
  desHabilitarReziseDrag() {
    this.activo = false;
    this.onDesHabilitarResizeDrag.emit(null);
  }

  addIndicador() {
    this.onAddIndicador.emit(null);
  }
}
