import { AfterViewInit, Component } from '@angular/core';

@Component({
  selector: 'app-layout-invoice',
  templateUrl: './layout-invoice.component.html',
  styleUrls: ['./layout-invoice.component.scss']
})
export class LayoutInvoiceComponent implements AfterViewInit {
  constructor(
  ) {
  }

  ngAfterViewInit(): void {
  }
}
