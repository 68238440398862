<!-- Sidebar -->
<p-sidebar [(visible)]="sidebar"  [style]="{width:'19rem'}" [showCloseIcon]="true" position="left" [dismissible]="true" [closeOnEscape]="true" [modal]="true">
    <app-sidebar></app-sidebar>
</p-sidebar>

<!-- navbar component -->
<app-navbar class="shadow" (sidebar)="sidebar = !sidebar" (sidebarMobile)="sidebarMobile = !sidebarMobile"></app-navbar>
<!-- router content -->

<div class="container-fluid" style="padding: 0; margin: 0; overflow-x: hidden;" id="cfBase">
    <router-outlet></router-outlet>
    <br>
    <br>
    <br>
</div>




