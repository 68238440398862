import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public href: string = "";

  urlActual = '';
  
  app: any = '';

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.href = this.router.url;
    // console.log(this.router.url.replace('/',''));
    this.urlActual = this.router.url.replace('/','');
  }

}
